import React from 'react'

export default function Spotlight(props) {
    // console.log(props.bgImg)
    return (
        <div className="spotlight" style={{backgroundImage: `url(${props.bgImg})`}}>
            <div>
                <h1>{props.title}</h1>
                <p>{props.subHead}</p>
            </div>
        </div>
    )
}
