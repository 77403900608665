import React from 'react'
import { Link } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Spotlight from "../../components/Spotlight"

export default function workTemplate(props) {
    // console.log(props)
    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <SEO title={props.pageContext.node.title} />
            <Spotlight title={props.pageContext.node.title} subHead={props.pageContext.node.excerpt} bgImg={props.pageContext.node.image} />
            <div className="article__Page">
                <Container>
                    <Row>
                        <Col>
                            <small className="breadcrumb">
                                <Link to="/">Home</Link> <div className="separator">/</div> <Link to={props.pageContext.node.category === `Work` ? `/work` : `/library`}>{props.pageContext.node.category}</Link> <div className="separator">/</div> {props.pageContext.node.title}
                            </small>
                            <div dangerouslySetInnerHTML={{__html:props.pageContext.node.content}} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}
